<template>
    <div class="payment">
        <div v-if="processing" class="payment-loading">
            <LoaderButton color="#fff" width="40" height="40"/>
        </div>
        <form class="payment-page" @submit.prevent="checkoutForm">
            <div class="payment-left">
                <h2>Στοιχεία πελάτη</h2>
                <div class="form-user account-info-form">
                    <div class="form-text">
                        <label for="name">Όνομα*</label>
                        <input required type="text" id="name" v-model="data.name" placeholder="Το όνομα σας"/>
                        <InputError :message="$attrs.errors.name" />
                    </div>
                    <div class="form-text">
                        <label for="lname">Επώνυμο*</label>
                        <input required type="text" id="lname" v-model="data.lname" placeholder="Το επώνυμο σας"/>
                        <InputError :message="$attrs.errors.lname" />
                    </div>
                    <div class="form-text">
                        <label for="phone">Τηλέφωνο*</label>
                        <input required type="tel" id="phone" v-model="data.phone" placeholder="Το τηλέφωνο σας"/>
                        <InputError :message="$attrs.errors.phone" />
                    </div>
                    <div class="form-text">
                        <label for="email">Email*</label>
                        <input required type="email" id="email" v-model="data.email" placeholder="Το email σας"/>
                        <InputError :message="$attrs.errors.email" />
                    </div>
                    <div class="form-text">
                        <label for="country">Χώρα*</label>
                        <select required v-model="data.country">
                            <option value="greece">Ελλάδα</option>
                            <option value="cyprus">Κύπρος</option>
                        </select>
                    </div>
                    <div class="form-text">
                        <label for="town">Πόλη / Περιοχή*</label>
                        <input required type="text" id="town" v-model="data.town" placeholder="Η πόλη / περιοχή σας"/>
                        <InputError :message="$attrs.errors.town" />
                    </div>
                    <div class="form-text">
                        <label for="county">Νομός*</label>
                        <input required type="text" id="county" v-model="data.state" placeholder="Ο νομός σας"/>
                        <InputError :message="$attrs.errors.state" />
                    </div>
                    <div class="form-text">
                        <label for="address">Διεύθυνση*</label>
                        <input required type="text" id="address" v-model="data.address" placeholder="Η διεύθυνση σας"/>
                        <InputError :message="$attrs.errors.address" />
                    </div>
                    <div class="form-text">
                        <label for="postal">Τ.Κ.*</label>
                        <input required type="number" id="postal" v-model="data.postal_code" placeholder="Ο ταχυδρομικός κώδικας σας"/>
                        <InputError :message="$attrs.errors.postal_code" />
                    </div>
                    <div class="form-text form-textarea">
                        <label for="comments">Σχόλια</label>
                        <textarea id="comments" v-model="data.comments" placeholder="Σχόλια σχετικά με την κράτηση"></textarea>
                    </div>
                </div>
                <h2 v-if="vehicle.can_santander">Επιλογές πληρωμής</h2>
                <div v-if="vehicle.can_santander" class="payment-santander form-text">
                    <div class="payment-santander-radio">
                        <input type="radio" :id="`can_santander-1`" v-model="santander" name="santander" value="Online κράτηση" />
                        <label for="can_santander-1">Online κράτηση</label>
                    </div>
                    <div class="payment-santander-radio">
                        <input type="radio" :id="`can_santander-2`" v-model="santander" name="santander" value="Online χρηματοδότηση" />
                        <label for="can_santander-2">Online χρηματοδότηση</label>
                    </div>
                </div>
                <div class="payment-left-bottom">
                    <div>
                        <img v-if="santander === 'Online χρηματοδότηση'" src="/images/santader-logo-red.png" width="102" height="18">
                        <VivaWallet v-else color="#f3f5fb"/>
                    </div>
                    <p v-if="santander === 'Online χρηματοδότηση'">Η online χρηματοδότηση παρέχεται από την Santander και ισχύουν όροι και προϋποθέσεις.</p>
                    <p v-else>Η πληρωμή πραγματοποιείται με το σύστημα πληρωμών της Viva Wallet</p>
                </div>
            </div>
            <div class="payment-right">
                <h2>Σύνοψη προϊόντος & Πληρωμή</h2>
                <div>
                    <Payment :order="vehicle" />
                    <div class="payment-right-form-box">
                        <input required type="checkbox" id="terms" @click="box.terms = !box.terms" />
                        <label for="terms" >Έχω διαβάσει και αποδέχομαι τους όρους πληρωμών</label>
                    </div>
                    <div class="payment-right-fixed">
                        <div class="payment-right-form-button">
                            <Button @click="checkoutForm" submit inline :disable="!box.terms" :processing="processing">Πληρωμή</Button>
                        </div>
                        <div class="payment-right-bottom">
                            <p>SECURE PAYMENT</p>
                            <ShieldCheck />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>

</template>

<script>
    import Button from "../../Components/Button/Button.vue";
    import { useForm } from "@inertiajs/vue3";
    import { reactive } from "vue";
    import { LoaderButton, ShieldCheck, VivaWallet } from "../../Icons/Icons";
    import InputError from "../../Components/Form/InputError.vue";
    import Payment from "../../Components/Cart/Payment.vue";

    export default {
        components: {
            Button, ShieldCheck, InputError, Payment,
            VivaWallet, LoaderButton,
        },
        props: {
            vehicle: Object,
            auth: Object,
        },
        setup(props, { slots, attrs }) {

            let data = useForm({
                name: '' ,
                lname: '',
                phone: '',
                email: props.auth.user.email,
                country: 'greece',
                address: '',
                postal_code: '',
                town: '',
                state: '',
                car_id: props.vehicle.id,
                comments: '',
            });

            const box = reactive({
                terms: false,
            })

            return { data, box };
        },
        data() {
            return {
                santander: this.vehicle.can_santander ? 'Online κράτηση' : '',
                processing: false,
            }
        },
        methods: {
            checkoutForm() {
                if ( this.vehicle.can_santander && this.santander === 'Online χρηματοδότηση' ) {
                    this.processing = true;
                    this.data.post('/online-financing/santander', {
                        onFinish: () => this.processing = false,
                        onError: (error) => { console.log(error); this.processing = false }
                    });
                } else {
                    this.processing = true;
                    this.data.post(window.location.href, {
                        onFinish: () => this.processing = false,
                        onError: (error) => { console.log(error); this.processing = false }
                    });
                }
            },
            isInViewport(el) {
                const rect = el.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            },
            stickyNav() {
                const prevElement = document.querySelector(".payment-right-form-box");
                const childElement = document.querySelector(".payment-right-fixed");

                if ( this.isInViewport(prevElement) ) {
                    childElement.classList.add("payment-right-relative");
                    childElement.classList.remove("payment-right-animation");
                } else {
                    childElement.classList.remove("payment-right-relative");
                    childElement.classList.add("payment-right-animation");
                }
            },
            getData() {
                if ( this.auth.user ) {
                    if ( this.auth.user.user_detail ) {
                        if ( this.auth.user.user_detail.first_name ) { this.data.name = this.auth.user.user_detail.first_name }
                        if ( this.auth.user.user_detail.last_name ) { this.data.lname = this.auth.user.user_detail.last_name }
                        if ( this.auth.user.user_detail.phone ) { this.data.phone = this.auth.user.user_detail.phone }
                        if ( this.auth.user.user_detail.country ) { this.data.country = this.auth.user.user_detail.country }
                        if ( this.auth.user.user_detail.address ) { this.data.address = this.auth.user.user_detail.address }
                        if ( this.auth.user.user_detail.postal_code ) { this.data.postal_code = this.auth.user.user_detail.postal_code }
                        if ( this.auth.user.user_detail.town ) { this.data.town = this.auth.user.user_detail.town }
                        if ( this.auth.user.user_detail.state ) { this.data.state = this.auth.user.user_detail.state }
                    }
                }
            }
        },
        mounted() {
            window.addEventListener("scroll", this.stickyNav);
            this.getData();
        },
        beforeUnmount() {
            window.removeEventListener("scroll", this.stickyNav);
        },
    }
</script>